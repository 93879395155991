/* You can add global styles to this file, and also import other style files */
/* @import '~material-design-icons/iconfont/material-icons.css'; */
/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */
@import 'variables.scss';

* {
  -webkit-user-select: text;
  -webkit-font-smoothing: antialiased !important;
}

body {
  background: $neutral-100 !important;
}

span,
fa-icon {
  font-size: $default_font_size;
  font-family: $font-primary;
  color: $neutral-800;
}

.mat-mdc-tooltip {
  white-space: pre-line !important;
  max-width: unset !important;
  padding: 8px !important;
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-container-color: #1A1A1A !important;
  --mdc-plain-tooltip-supporting-text-color: #FFF !important;
  --mdc-plain-tooltip-supporting-text-weight: 400;
}

.mat-mdc-paginator-icon {
  fill: $neutral-800 !important;
}

.mat-mdc-icon-button:disabled {
  opacity: 0.2 !important;
}

.mat-mdc-raised-button {
  box-shadow: none !important;
}

.ngx-charts text {
  fill: $neutral-800 !important;
  font-size: 11px;
}

.ngx-charts .gridline-path {
  display: none;
}

.ngx-charts .bar {
  fill: $accent;
}

.primary-color {
  background-color: $neutral-50 !important;
  color: $neutral-800 !important;
}

.third-color {
  background-color: $neutral-0 !important;
  color: $neutral-800 !important;
}

.accent {
  background-color: $accent !important;
}

.last-accesses-img {
  width: 40px;
  border-radius: 50% !important;
  float: left;
}

/* DEAFULT COLORS */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-primary-color !important;
}

input {
  color: $neutral-800;
  font-size: $default_font_size;
}

.mat-mdc-input-element {
  caret-color: $neutral-800 !important;

  ::placeholder {
    color: $neutral-800 !important;
    opacity: 0.6;
  }
}

@media screen and (max-width: 500px) {
  ::-webkit-scrollbar-thumb {
    display: flex !important;
    background-color: $accent !important;
  }

  ::-webkit-scrollbar {
    width: 0.2em !important;
    height: 0.2em !important;
  }

  .dialog-container .mat-mdc-dialog-container {
    height: 100vh !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px $neutral-0 inset !important;
  -webkit-text-fill-color: $neutral-800 !important;

  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0 30px $neutral-0 inset !important;
    -webkit-text-fill-color: $neutral-800 !important;
  }
}

fa-icon {
  font-size: 15px;
}

/* MAT SELECT */
.mat-mdc-select,
.mat-mdc-options {
  z-index: 999999 !important;
  display: flex !important;
  align-items: center !important;
}

/*  MAT FORM FIELD  */
.date-input .mat-mdc-form-field-infix {
  border-top-width: 6px !important;
}

.mat-mdc-form-field-appearance-outline {
  .mat-mdc-form-field-outline {
    color: $accent !important;
    margin-top: -3px !important;
  }

  .mat-mdc-form-field-infix {
    height: 40px !important;
    padding: 0 !important;
    position: initial !important;
    line-height: 40px;
    display: flex;
  }

  .mat-mdc-form-field-flex {
    border-radius: 4px !important;
  }
}

.mat-mdc-form-field-infix {
  min-height: auto !important;
}

.mat-mdc-form-field-flex,
.mat-mdc-form-field-infix,
input.mat-mdc-input-element::placeholder {
  color: $neutral-800 !important;
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  padding: 0 !important;
}

.iti__country-list {
  background-color: $neutral-0;
  color: $neutral-800;
}

.iti input {
  width: 400px;
  background-color: $neutral-0 !important;
  border: 1.5px solid $border-main;
  color: $neutral-800 !important;
  border-radius: 4px;
  height: 42px !important;

  &:focus {
    border-radius: 4px;
    border: 1.5px solid $accent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.iti__flag-container {
  background-color: $neutral-0;
}

.country-dropdown {
  padding: 0 !important;
  border: none !important;
}

input.mat-mdc-input-element::placeholder {
  opacity: 0.6 !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-form-field-suffix {
  line-height: 20px;
  top: 0 !important;
}

.mat-mdc-form-field-icon-suffix {
  padding-right: 10px !important;
}

.mat-mdc-form-field-icon-prefix {
  padding: 0 0 0 14px !important;
}

.mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  padding-left: 5px !important;
  height: 100% !important;
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 24px !important;
}

.mat-mdc-paginator-container {
  .mat-mdc-form-field-infix {
    background-color: $neutral-100 !important;
    border-radius: 4px;
    height: 100%;
  }

  .mat-mdc-form-field-flex {
    width: 70px;
  }

  .mat-mdc-text-field-wrapper {
    width: inherit;
    background-color: transparent !important;
  }

  .mdc-notched-outline {
    display: none !important;
  }
}

.mat-mdc-menu-submenu-icon {
  fill: $neutral-800 !important;
}

.mat-mdc-menu-item.mdc-list-item {
  background-color: $neutral-0 !important;
}

.mat-mdc-menu-item:hover {
  background-color: $neutral-200 !important;
}

.mat-mdc-card-content {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.mat-mdc-table {
  background-color: $neutral-0 !important;
  color: $neutral-800;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
  background-color: $neutral-0 !important;
}

.mdc-menu-surface--open {
  background-color: $neutral-0 !important;
}

.mdc-dialog__title {
  padding: 0 !important;
}

.mat-mdc-select-arrow-wrapper {
  margin-right: 10px;
}

.cdk-overlay-container {
  z-index: 500 !important;
}

.mat-mdc-menu-content {
  background-color: $neutral-0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: $neutral-800 !important;
}

/* Removes the clear button from date inputs */
input[type='date'] {

  &::-webkit-clear-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    color: #2c3e50;
  }
}

.fill-remaining-space {
  flex: 1 1 auto;
}

input[type='time'] {
  height: 40px;
  appearance: none;
  color: $neutral-800;
  font-size: $default_font_size;
  background-color: $neutral-0 !important;
  border-radius: 4px !important;
  padding: 5px 10px;
  border: 0px;
  visibility: visible !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(0);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: $neutral-0 !important;
}

.modal-height-1 {
  min-height: 430px !important;
}

.mat-mdc-dialog-title {
  font-size: 22px !important;
  margin: 0 0 $default_padding !important;
}

.mat-calendar-table {
  border: none !important;
  background-color: $neutral-0 !important;
  box-shadow: none;
}

.dialog-container .mat-mdc-dialog-container {
  background-color: $neutral-0;
  padding-bottom: 0px !important;
  border: 1px solid $neutral-0 !important;
  border-radius: 10px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  padding: $default_padding !important;
}

.dialog-container .mat-mdc-dialog-content {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.mat-mdc-list-base {
  padding: 0 !important;
}

.mat-mdc-form-field-disabled,
.mat-mdc-input-element:disabled,
.mat-input:disabled {
  color: $neutral-400 !important;
}

.mat-horizontal-stepper-header-container {
  height: 40px;
  padding: 0 !important;
  pointer-events: none;
  width: 100%;
  line-height: 20px;
  margin-bottom: $default_padding;
}

.mat-step-label {
  padding: 0 !important;
  text-align: center !important;
  color: $neutral-400 !important;
  font-weight: 700 !important;
}

.mat-step-header:has(.mat-step-label-selected, .mat-step-label-active) {
  .mat-step-header-ripple {
    border-bottom: 3px solid $accent !important;
  }
}

.mat-step-label-active,
.mat-step-label-selected {
  color: $accent !important;
}

.mat-horizontal-stepper-header {
  padding: 0 !important;
  height: 40px !important;
}

.mat-step-header {
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mat-step-header[aria-selected='true'] {
  z-index: 1;
  font-weight: 800;
  border-radius: 5px 5px 0 0;
  color: $accent !important;
  font-weight: 700 !important;
  background-color: rgba(22, 157, 207, 0.15) !important;
}

.mat-step-header-ripple {
  border-bottom: 3px solid #b4b7c8;
}

.mat-horizontal-content-container {
  padding: 0 !important;
  overflow-y: hidden !important;
}

/* CALENDAR STYLE */
.mat-calendar {
  color: $neutral-800 !important;
  background-color: $neutral-0 !important;
}

.mat-datepicker-toggle {
  color: $neutral-800 !important;
}

.mat-calendar-body-selected,
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(22, 157, 207, 0.15) !important;
}

.mat-datepicker-content {

  .mat-calendar-next-button,
  .mat-calendar-previous-button .mat-calendar-table-header {
    color: $neutral-800 !important;
  }
}

.mat-calendar-previous-button .mat-mdc-icon-button,
.mat-calendar-next-button .mat-icon-button {
  color: $neutral-800 !important;
}

.mat-calendar-table-header {
  color: inherit !important;
}

.mat-calendar-body-cell-content,
.mat-calendar-body-label {
  color: $neutral-800 !important;
}

.mat-mdc-form-field {
  margin-top: 3px;
}

.mat-mdc-form-field-flex {
  caret-color: $neutral-800;
  color: $neutral-800 !important;
  padding-top: 0px !important;
  height: 40px !important;
  align-items: center !important;
}

/* POSITION */
.centered {
  display: flex;
  width: 100%;
  height: calc(100vh - 180px);
  justify-content: center;
  align-items: center;
}

.center-spinner {
  margin: 0 auto;
}

span.swal2-success-line-long,
span.swal2-success-line-tip {
  font-size: 1rem !important;
}

.swal2-container {
  z-index: 999999 !important;
}

h2,
swal2-title,
.swal2-title .span {
  color: black !important;
}

.swal2-content,
#swal2-content {
  color: $neutral-800 !important;
  font-size: $default_font_size !important;
  font-weight: 500 !important;
}

.btn-label {
  color: $neutral-0 !important;
  font-weight: 500;
}

button {
  height: 40px;
}

.end {
  text-align: end;
}

.toast-popup {
  background-color: $neutral-0 !important;
}

.toast-title {
  color: $neutral-800 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.bold {
  font-weight: bold;
}

.mat-elevation-z8 {
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2) !important;
}

.mat-mdc-form-field-error {
  font-size: 12px !important;
  min-height: 5px !important;
  width: 100% !important;
  color: $critical !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: $accent !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-option.mdc-list-item,
.mat-mdc-select-arrow svg,
.mdc-form-field>label {
  color: $neutral-800 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100% !important;
}

table {
  border-collapse: collapse;
  border-radius: 16px !important;
  width: 100%;
  background-color: $neutral-0;
  color: $neutral-800;
  box-shadow: 0px 1px 4px rgba(18, 21, 27, 0.15);
}

tr {
  &:not(.mat-mdc-header-row):hover {
    background-color: $neutral-200 !important;
    border-left: 3px solid $accent;
    cursor: pointer;
  }
}

input.mat-mdc-input-element {
  padding-left: 10px !important;
}

.mat-mdc-paginator-outer-container {
  background-color: $neutral-0;
}

.mat-mdc-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 56px;
  padding: 0 16px;
  flex-wrap: wrap-reverse;
  width: 100%;
  background-color: $neutral-0;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-right: 1px solid $neutral-400;
  border-bottom: 1px solid $neutral-400;
  border-left: 1px solid $neutral-400;

  .mat-mdc-select-arrow,
  .mat-mdc-select-value-text {
    color: $neutral-800 !important;
  }
}

.ngx-datatable.material {
  background-color: transparent !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.ngx-datatable.pointer .datatable-row-center {
  cursor: pointer;
} 

.ngx-datatable .datatable-row-center, tr.mat-mdc-row {
  width: 100% !important;
  border-bottom: 1px solid $neutral-50;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: $neutral-200 !important;
  transition: none;
  border-radius: 0 !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell,
.ngx-datatable.material .datatable-body,
.ngx-datatable.material .datatable-body:hover {
  background-color: $neutral-0 !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  width: auto !important;
  overflow-x: scroll;
}

.datatable-body-cell {
  color: $neutral-800 !important;
  width: 100% !important;
}

.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid $neutral-400;
  background-color: $neutral-100;

  span {
    color: $neutral-800;
  }
}

.ngx-datatable .datatable-footer {
  overflow: hidden !important;
  background-color: $neutral-0 !important;

  .datatable-pager .pager li a {
    color: $neutral-800 !important;
  }

  .datatable-pager .pager li a svg {
    fill: $neutral-800 !important;
  }

  .datatable-pager .pager li.disabled a svg {
    fill: rgba(0, 0, 0, .38) !important;
  }
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
  margin-left: 16px !important;

  span {
    color: $text-primary-color;
  }
}

.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 24px;
}

.ngx-datatable .datatable-body {
  min-height: calc(100vh - 300px);
  background: $neutral-0 !important;
}

.ngx-datatable .datatable-body .datatable-scroll {
  overflow-x: hidden !important;
  width: 100% !important;
  height: calc(100vh - 332px) !important;
  background: $neutral-0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @media screen and (max-width: 900px) {
    overflow-x: auto !important;
  }
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #366B00 !important;
  --mdc-switch-selected-handle-color: #366B00 !important;
  --mdc-switch-selected-track-color: #366B00 !important;
  --mdc-switch-selected-handle-color: #366B00 !important;
  --mdc-switch-selected-focus-handle-color: #366B00 !important;
  --mdc-switch-selected-hover-handle-color: #366B00 !important;
  --mdc-switch-selected-pressed-handle-color: #366B00 !important;
  --mdc-switch-selected-focus-track-color: #366B00 !important ;
  --mdc-switch-selected-hover-track-color: #366B00 !important;
  --mdc-switch-disabled-selected-handle-color: #4e4e4e;
  --mdc-switch-disabled-unselected-handle-color: #4e4e4e;
  --mdc-switch-disabled-selected-track-color: #4e4e4e;
  --mdc-switch-disabled-unselected-track-color: #4e4e4e;
}

.mat-mdc-select-value {
  padding-left: 10px !important;
}

.ngx-datatable.material {
  background-color: transparent !important;
  border-radius: 8px !important;
}

.ngx-datatable .datatable-row-center {
  width: 100% !important;
}

.datatable-body-row:hover {
  background-color: $neutral-200 !important;
  transition: none;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell,
.ngx-datatable.material .datatable-body {
  background-color: $neutral-0 !important;
  width: 100% !important;
}


.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  width: auto !important;
  overflow-x: scroll;
}

.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid $neutral-400;
  background-color: $neutral-100;

  span {
    color: $neutral-800;
    font-weight: 600;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: $font-primary !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
  max-height: 50vh;
}

.mat-toolbar,
.mat-toolbar-row,
.mat-toolbar-single-row {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: $neutral-600 !important;
}

::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  display: flex !important;
  background-color: $accent !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.mat-mdc-card {
  padding: 16px;
  background: $neutral-0 !important;
  box-shadow: none !important;
  border: 1px solid $neutral-400 !important;
}

.mat-mdc-card::-webkit-scrollbar-thumb {
  height: 50px !important;
  border: 8px solid rgba(0, 0, 0, 0) !important;
}

@media (max-width: 500px) {
  .steppers {
    display: flex;
    height: 120px;
    width: 100%;
    background-color: $neutral-0;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 2px 0px 8px rgba(18, 21, 27, 0.15);
  }
}

.timepicker {

  .timepicker__body,
  .timepicker__actions {
    background-color: $neutral-0 !important;
  }

  .timepicker__actions {
    justify-content: space-between !important;
  }

  .clock-face {
    background-color: $neutral-0 !important;

    span {
      color: $neutral-800 !important;
    }
  }

  .timepicker-button {
    background-color: $neutral-700 !important;
    margin: 0 10px;
    color: $neutral-800 !important;

    .span {
      color: $neutral-800 !important;
      font-weight: 400 !important;
    }
  }

  .timepicker-button:hover {
    filter: brightness(120%) !important;
  }
}

::ng-deep .mat-step-header[aria-selected='true'] {
  z-index: 1;
  font-weight: 800;
  border-bottom: 3px solid $accent;
  border-radius: 5px 5px 0 0;
  color: $accent !important;
  font-weight: 700 !important;
  background-color: rgba(22, 157, 207, 0.15) !important;
}