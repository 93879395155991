/*----------------------------
--------- 1. General ---------
----------------------------*/


@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto:300,400,500,700');

html, html a, body {
   -webkit-font-smoothing: antialiased;
}

body {
   font-family: $font-primary;
   font-size: 14px;
   background-color: $neutral-200;
   color: $neutral-700;
   line-height: 1.5;
   letter-spacing: 0.2px;
   overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $neutral-700;
	font-family: $font-primary;
	letter-spacing: 0.5px;
	font-weight: normal;
	line-height: 1.5;
	
	a {
		font-family: $font-primary;
	}
	
	small {
		font-weight: 300;
		color: lighten($neutral-700,5%);
	}
}

h1 {
	font-size: 26px;
}

h2 {
	font-size: 22px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 12px;
}

p {
	font-family: $font-primary;
	color: $neutral-700;
	line-height: 1.9;
}
