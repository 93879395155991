//==================================
// 	Fonts
//==================================

$font-primary: 'Noto Sans', sans-serif;
$default_font_size: 14px;

//==================================
// 	App colors
//==================================

$accent: #00A0D0;
$accent-600: #F39237;
$accent-hover: #007699;
$border-main: #707070;
$color-success-100: #F5F9F2;
$color-success-800: #366B00;
$color-warning: #FFAE00;
$critical: #BA1A1A;
$hover-back-image: rgba(255, 255, 255, 0.6);
$neutral-0: #FFFFFF;
$neutral-50: #F4F4F4;
$neutral-100: #FAFAFA;
$neutral-200: #F0F0F0;
$neutral-400: #D9D9D9;
$neutral-600: #919191;
$neutral-700: #5C5C5C;
$neutral-800: #262626;
$primary-500: #7CC1DE;
$primary-600: #00A0D0;
$primary-900: #1F627F;
$text-primary-color: #1A1A1A;
$header_height: 50px;

//==================================
// 	Margins
//==================================

$default_padding: 32px;
$default_margin: 24px;